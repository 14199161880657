.SC_Container {
  width: 10rem;
  height: 10rem;
  position: fixed;
  z-index: 100;
  right: 1rem;
  bottom: 0;
  transition: width 0.5s ease-in-out;
  padding: 1rem;
}

.SC_Container.showFull {
  width: 30rem; /* Adjust the width as needed */
  height: 10rem;
}

.github {
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  /* background-color: #0d1117; */
  border-radius: 1rem;
  padding: 1rem;
  gap: 1rem;
  text-align: center;
}
.github.showFull {
  box-shadow: 0 0 1rem black;
  background-color: #0d1117;
}
.github:hover {
  cursor: pointer;
}

.git_Logo,
.git_Button {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 3rem;
  transition: transform 1s ease;
}
.git_Logo {
  background-color: #0d1117;
  border-radius: 1rem;
}
.git_Logo.showFull {
  transform: rotate(360deg);
  background-color: transparent;
  border-radius: 1rem;
}
.git_Butt {
  width: auto;
  height: auto;
  background-color: #238636;
  font-size: 1.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem;
}
@media screen and (max-width: 1240px) {
    .SC_Container {
        width: 8rem;
        height: 8rem;
        z-index: 100;
        right: 1rem;
        bottom: 0;
      }
      
      .SC_Container.showFull {
        width: 20rem; /* Adjust the width as needed */
        height: 8rem;
      } 
      .github {
        height: 4rem;
        border-radius: 1rem;
        padding: 1rem;
        gap: 1rem;
      }
      
      .git_Logo,
      .git_Button {
        font-size: 2rem;
      }
      .git_Logo {
        border-radius: 1rem;
      }
      .git_Logo.showFull {
        border-radius: 1rem;
      }
      .git_Butt {
        font-size: 1rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 0.5rem;
      }
}
@media screen and (max-width: 1024px) {
    .SC_Container {
        width: 8rem;
        height: 10rem;
        z-index: 100;
        right: 1rem;
        bottom: 0;
      }
      
      .SC_Container.showFull {
        width: 20rem; /* Adjust the width as needed */
        height: 10rem;
      } 
      .github {
        height: 5rem;
        border-radius: 1rem;
        padding: 1rem;
        gap: 1rem;
      }
      
      .git_Logo,
      .git_Button {
        font-size: 2rem;
      }
      .git_Logo {
        border-radius: 1rem;
      }
      .git_Logo.showFull {
        border-radius: 1rem;
      }
      .git_Butt {
        font-size: 1rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 0.5rem;
      }
}
@media screen and (max-width: 768px) {
    .SC_Container {
        width: 8rem;
        height: 10rem;
        z-index: 100;
        right: 1rem;
        bottom: 2rem;
      }
      
      .SC_Container.showFull {
        width: 20rem; /* Adjust the width as needed */
        height: 10rem;
      } 
      .github {
        height: 5rem;
        border-radius: 1rem;
        padding: 1rem;
        gap: 1rem;
      }
      
      .git_Logo,
      .git_Button {
        font-size: 2rem;
      }
      .git_Logo {
        border-radius: 1rem;
      }
      .git_Logo.showFull {
        border-radius: 1rem;
      }
      .git_Butt {
        font-size: 1rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: 0.5rem;
      }
}