.Socials_Container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.Socials_Content {
  width: 75%;
  height: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.Socials {
  width: 100%;
  height: 100%;
  padding: 2rem;
  gap: 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.social {
  width: 5rem;
  height: 5rem;
  box-shadow: 0 0 1rem black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  background-color: var(--color-primary);
  color: #e4b884;
  border-radius: 1rem;
}
.social:hover {
  transform: scale(1.1);
  cursor: pointer;
}
@media screen and (max-width: 1240px) {
  .Socials_Content {
    width: 75%;
  }
  .Socials {
    padding: 2rem;
    gap: 2rem;
  }
  .social {
    width: 5rem;
    height: 5rem;
    font-size: 6rem;
  }
}
@media screen and (max-width: 1024px) {
  .Socials_Content {
    width: 85%;
  }
  .Socials {
    padding: 2rem;
    gap: 2rem;
  }
  .social {
    width: 5rem;
    height: 5rem;
    font-size: 5rem;
  }
}
@media screen and (max-width: 768px) {
  .Socials_Content {
    width: 95%;
  }
  .Socials {
    padding: 1rem;
    gap: 1rem;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .social {
    width: 5rem;
    height: 5rem;
    box-shadow: 0 0 0.5rem black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    background-color: var(--color-primary);
    color: #e4b884;
    border-radius: 1rem;
  }
  .social:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}
@media screen and (max-width: 500px) {
  .Socials_Container {
    height: 40vh;
    padding: 0;
    padding-top: 1rem;
  }
  .Socials_Content {
    width: 95%;
  }
  .Socials {
    padding: 1rem;
    gap: 1rem;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .social {
    width: 3rem;
    height: 3rem;
    box-shadow: 0 0 0.5rem black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    background-color: var(--color-primary);
    color: #e4b884;
    border-radius: 1rem;
  }
  .social:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}
