.Footer_Container {
  width: 100%;
  height: 40vh;
  background-color: var(--color-primary);
  background-image: url("https://i.ibb.co/dg4NRY7/texture.png");
  background-size: cover;
  background-blend-mode: color-burn;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 2rem 0;
}
.Footer_Content {
  width: 75%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: center;
}
.Footer_Logo {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Footer_Logo img {
  width: 10rem;
}
.Footer_Details {
  flex: 4;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty_div{
  flex: 1;
}
.Details {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.Details span:nth-child(1) {
  color: white;
  font-size: 2rem;
}
.Details span:nth-child(2) {
  color: #c7884b;
  font-size: 1rem;
}

.Developer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Developer:hover {
  cursor: pointer;
}
.Developer span {
  font-size: 1rem;
  color: white;
}
.Developer span:hover {
  color: #c7884b;
}
@media screen and (max-width: 1240px) {
  .Footer_Container {
    height: auto;
    padding: 2rem 0 2rem 0;
  }
  .Footer_Content {
    width: 75%;
  }
  .Footer_Logo img {
    width: 8rem;
  }
  .Details span:nth-child(1) {
    font-size: 1.7rem;
  }
  .Details span:nth-child(2) {
    font-size: 0.8rem;
  }
  .Developer span {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 1024px) {
  .Footer_Container {
    height: auto;
    padding: 2rem 0 2rem 0;
  }
  .Footer_Content {
    width: 75%;
  }
  .Footer_Logo img {
    width: 6rem;
  }
  .Details span:nth-child(1) {
    font-size: 1.5rem;
  }
  .Details span:nth-child(2) {
    font-size: 0.6rem;
  }
  .Developer span {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 768px) {
  .Footer_Container {
    height: auto;
    padding: 2rem 0 2rem 0;
  }
  .Footer_Content {
    width: 85%;
  }
  .Footer_Logo img {
    width: 4rem;
  }
  .Details span:nth-child(1) {
    font-size: 1.3rem;
  }
  .Details span:nth-child(2) {
    font-size: 0.6rem;
  }
  .Developer span {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 500px) {
  .Footer_Container {
    height: 40vh;
    background-repeat: no-repeat;
    padding: 1rem 0 1rem 0;
    padding-bottom: 5rem;
  }
  .Footer_Content {
    width: 95%;
  }

  .Footer_Logo img {
    width: 5rem;
  }

  .Details span:nth-child(1) {
    color: white;
    font-size: 0.8rem;
  }
  .Details span:nth-child(2) {
    color: #c7884b;
    font-size: 0.5rem;
  }

  .Developer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Developer span {
    font-size: 0.5rem;
    color: white;
  }
}
