.Loading_Container {
  position: absolute;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Loading_Container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1rem); /* Adjust the blur strength as needed */
  filter: blur(
    1rem
  ); /* Fallback for browsers without backdrop-filter support */
  z-index: -1;
}

.Loading_Container img {
  width: 20rem;
}
@media screen and (max-width: 1440px) {
 
  .Loading_Container img {
    width: 10rem;
  }
}
