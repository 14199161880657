nav {
    background: rgba(0, 0, 0, 0.3);
    width: 80%;
    display: block;
    padding: 0.5rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0.2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;

}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
}

nav a.active {
    background: var(--color-primary);
    color: var(--color-bg);
}
.NavImage{
    width: 1.1rem;
}
/* media queries-small  */
