.Downloadnow_container {
  width: 100%;
  height: 65vh;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  align-self: center;
  background-image: url("https://i.ibb.co/dg4NRY7/texture.png");
  background-size: cover;
  background-blend-mode: color-burn;
  background-position: center;
}
.dn_content {
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}
.dn_content_right,
.dn_content_left {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dn_content_right {
  gap: 1rem;
  color: white;
  font-size: 3rem;
  flex: 1;
}

.dn_content_left {
  flex: 1;
  gap: 1rem;
  padding: 1rem;
  justify-content: center;
}
.download_button {
  width: 20rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.download_button:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.google_play {
  color: white;
  border: 1px solid white;
  border-radius: 10rem;
}
.ios {
  color: white;
  background-color: #4f4f8f;
  border-radius: 10rem;
}
/* .app_gallery {
  color: #ef6b53;
  border: 1px solid #ef6b53;

  border-radius: 10rem;
} */
.app_gallery {
  color: white;
  background-color: #ef6b53;
  border-radius: 10rem;
}
.db_left {
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.db_right {
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 2rem;
}
.important {
  text-shadow: 0 0 1rem white;
}
@media screen and (max-width: 1240px) {
  .Downloadnow_container {
    height: 55vh;
    margin-top: 2rem;
  }
  .dn_content {
    width: 75%;
  }
  .dn_content_right {
    font-size: 2rem;
  }
  .dn_content_left {
    gap: 1rem;
    padding: 1rem;
  }
  .download_button {
    width: 15em;
    height: 6rem;
    gap: 0.5rem;
  }
  .google_play {
    border-radius: 10rem;
  }
  .ios {
    border-radius: 10rem;
  }
  .app_gallery {
    border-radius: 10rem;
  }
  .db_left {
    font-size: 3rem;
  }
  .db_right {
    font-size: 1rem;
  }
 
}
@media screen and (max-width: 768px) {
  .Downloadnow_container {
    height: 45vh;
    margin-top: 2rem;
  }
  .dn_content {
    width: 85%;
  }
  .dn_content_right {
    font-size: 1.5rem;
  }
  .dn_content_left {
    gap: 1rem;
    padding: 1rem;
  }
  .download_button {
    width: 12em;
    height: 4rem;
    gap: 0.5rem;
  }
  .google_play {
    border-radius: 10rem;
  }
  .ios {
    border-radius: 10rem;
  }
  .app_gallery {
    border-radius: 10rem;
  }
  .db_left {
    font-size: 2rem;
  }
  .db_right {
    font-size: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .Downloadnow_container {
    width: 100%;
    height: 30vh;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    align-self: center;
    background-image: url("https://i.ibb.co/dg4NRY7/texture.png");
    background-size: cover;
    background-blend-mode: color-burn;
    background-position: center;
  }
  .dn_content {
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
  }
  .dn_content_right,
  .dn_content_left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dn_content_right {
    gap: 1rem;
    color: white;
    font-size: 1rem;
    flex: 1;
  }

  .dn_content_left {
    flex: 1;
    gap: 1rem;
    padding: 1rem;
    justify-content: center;
  }
  .download_button {
    width: 10rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .download_button:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  .google_play {
    color: white;
    border: 1px solid white;
    border-radius: 10rem;
  }
  .ios {
    color: white;
    background-color: #4f4f8f;
    border-radius: 10rem;
  }
  /* .app_gallery {
    color: #ef6b53;
    border: 1px solid #ef6b53;
  
    border-radius: 10rem;
  } */
  .app_gallery {
    color: white;
    background-color: #ef6b53;
    border-radius: 10rem;
  }
  .db_left {
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .db_right {
    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 1rem;
  }
  .important {
    text-shadow: 0 0 1rem white;
  }
}
